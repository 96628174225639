<template>
  <component is="b-card">
    <b-tabs pills>
      <!-- Tab: Received -->
      <template>
        <b-tab active @click="ongoingQuotationClicked">
          <template #title>
            <span class="d-none d-sm-inline">{{
              $t("Created Quotations")
            }}</span>
          </template>
          <on-going-quotation
            ref="ongoingQuotationRef"
            class="mt-2 pt-75"
            :clients="clients"
          />
        </b-tab>
        <b-tab @click="sentQuotationClicked">
          <template #title>
            <span class="d-none d-sm-inline">{{ $t("Sent Quotations") }}</span>
          </template>
          <sent-quotation
            ref="sentQuotationRef"
            class="mt-2 pt-75"
            :clients="clients"
          />
        </b-tab>
        <b-tab @click="verifyQuotationClicked">
          <template #title>
            <span class="d-none d-sm-inline">{{ $t("To Verified") }}</span>
          </template>
          <verify-quotation
            ref="verifyQuotationRef"
            class="mt-2 pt-75"
            :clients="clients"
            :subProfiles="subProfiles"
          />
        </b-tab>

        <b-tab @click="validQuotationClicked">
          <template #title>
            <span class="d-none d-sm-inline">{{ $t("Valid Quotations") }}</span>
          </template>
          <valid-quotation
            ref="validQuotationRef"
            class="mt-2 pt-75"
            :clients="clients"
            :subProfiles="subProfiles"
          />
        </b-tab>
        <b-tab @click="expiredQuotationClicked">
          <template #title>
            <span class="d-none d-sm-inline">{{
              $t("Expired Quotations")
            }}</span>
          </template>
          <expired-quotation
            ref="expiredQuotationRef"
            class="mt-2 pt-75"
            :clients="clients"
            :subProfiles="subProfiles"
            :quotationUsers="quotationUsers"
          />
        </b-tab>
      </template>
    </b-tabs>
  </component>
</template>

<script>
import { BTab, BTabs, BCard, BAlert, BLink } from "bootstrap-vue";

import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";

import instance from "@/libs/axios";
import OnGoingQuotation from "./OnGoingQuotation.vue";
import ValidQuotation from "./ValidQuotation.vue";
import VerifyQuotation from "./VerifyQuotation.vue";
import ExpiredQuotation from "./ExpiredQuotation.vue";
import SentQuotation from "./SentQuotation.vue";

export default {
  components: {
    BTab,
    BTabs,
    BCard,
    BAlert,
    BLink,
    OnGoingQuotation,
    ValidQuotation,
    VerifyQuotation,
    ExpiredQuotation,
    SentQuotation,
  },
  data() {
    return {
      subProfiles: [],
      emergencyDegrees: [],
      quotationUsers: [],
      clients: [],
      isAdmin: false,
    };
  },
  methods: {
    ongoingQuotationClicked() {
      this.$refs["ongoingQuotationRef"].refreshTable();
    },
    expiredQuotationClicked() {
      this.$refs["expiredQuotationRef"].refreshTable();
    },
    validQuotationClicked() {
      this.$refs["validQuotationRef"].refreshTable();
    },
    verifyQuotationClicked() {
      this.$refs["verifyQuotationRef"].refreshTable();
    },
    sentQuotationClicked() {
      this.$refs["sentQuotationRef"].refreshTable();
    },
  },
  created() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    this.isAdmin = userData?.role === "admin";
  },

  async mounted() {
    try {
      const res = await Promise.all([
        instance.get("/parameters/clients/"),
        instance.get("/parameters/subprofiles/"),
        instance.get("/quotations/users/"),
      ]);
      this.clients = res[0].data.results;
      this.subProfiles = res[1].data;
      this.quotationUsers = res[2].data;
    } catch (err) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title: this.$t("Error"),
          text: err.message,
          icon: "AlertTriangleIcon",
          variant: "danger",
        },
      });
    }
  },
};
</script>

<style></style>
