<template>
  <div>
    <!-- User Info: Input Fields -->
    <b-form>
      <b-row>
        <b-col>
          <b-form-group :label="$t('Date')" style="font-size: 0.857rem">
            <flat-pickr v-model="selectedDate" class="form-control" />
          </b-form-group>
        </b-col>
        <!-- Field: Ref -->
        <b-col>
          <b-form-group :label="$t('Ref')" label-for="ref" debounce="500">
            <b-form-input id="ref" v-model="refValue" />
          </b-form-group>
        </b-col>

        <b-col v-if="isAdmin">
          <b-form-group :label="$t('Sub profile')" label-for="client">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="subProfiles"
              label="name"
              input-id="client"
              v-model="subProfileValue"
            />
          </b-form-group>
        </b-col>

        <b-col v-if="isAdmin">
          <b-form-group :label="$t('Created By')" label-for="quotation-user">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="quotationUsers"
              label="name"
              input-id="quotation-user"
              v-model="createdByValue"
            />
          </b-form-group>
        </b-col>
        <!-- Field: Client -->
        <b-col>
          <b-form-group :label="$t('Client')" label-for="client">
            <v-select
              :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
              :options="clients"
              label="name"
              input-id="client"
              v-model="clientValue"
              @search="fetchClientOptions"
            />
          </b-form-group>
        </b-col>
      </b-row>
    </b-form>
    <b-card no-body class="mb-0">
      <b-table
        ref="refOrderListTable"
        class="position-relative"
        :items="fetchQuotations"
        responsive
        :fields="tableColumns"
        primary-key="id"
        :sort-by.sync="sortBy"
        show-empty
        :empty-text="$t('No matching records found')"
        :sort-desc.sync="isSortDirDesc"
      >
        <template #cell(alert)="data">
          <feather-icon
            icon="BellIcon"
            size="21"
            class="text-danger"
            :badge="data.item.price_changed"
            badge-classes="badge-danger badge-glow"
          />
        </template>
        <!-- Column: Actions -->
        <template #cell(actions)="data">
          <b-button
            v-ripple.400="'rgba(113, 102, 240, 0.15)'"
            variant="outline-primary"
            class="btn-icon mr-1"
            @click="openConsulteModal(data)"
          >
            <feather-icon icon="ClipboardIcon" size="16" />
          </b-button>

          <b-modal
            :ref="'consuler' + data.item.id + 'modal'"
            id="modal-xl"
            ok-only
            ok-title="OK"
            centered
            size="xl"
            :title="$t('Consulte')"
          >
            <b-row class="mb-4">
              <b-col>
                <b-col>
                  <b-form-group :label="$t('Ref')" label-for="ref">
                    <b-form-input disabled :value="data.item.ref" />
                  </b-form-group>
                  <b-form-group :label="$t('Client')" label-for="ref">
                    <b-form-input disabled :value="data.item.client.name" />
                  </b-form-group>
                </b-col>
              </b-col>
              <b-col>
                <b-col>
                  <b-form-group :label="$t('Start date')" label-for="ref">
                    <b-form-input disabled :value="data.item.start_date" />
                  </b-form-group>
                </b-col>
                <b-col>
                  <b-form-group :label="$t('End date')" label-for="ref">
                    <b-form-input disabled :value="data.item.end_date" />
                  </b-form-group>
                </b-col>
              </b-col>
            </b-row>
            <b-table
              :items="quotationDetails"
              responsive
              :fields="['product', 'quantity', 'min_price', 'price']"
              show-empty
              :empty-text="$t('No matching records found')"
            >
              <template #cell(product)="data">
                {{ data.item.product_combination.product_shortcut }}
              </template>
              <template #cell(quantity)="data">
                {{ data.item.quantity }}
                {{ data.item.product_combination.unity.name }}
              </template>
              <template #cell(min_price)="data">
                {{ data.item.min_price.min_price }} DH
              </template>
              <template v-if="isAdmin" #cell(price)="data">
                <span
                  :style="
                    data.item.min_price.min_price > data.item.price
                      ? 'color: red'
                      : ''
                  "
                >
                  {{ data.item.price }} DH
                </span>
              </template>
            </b-table>
          </b-modal>
        </template>
      </b-table>
      <div class="mx-2 mb-2">
        <b-row>
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-start"
          >
            <span class="text-muted">{{
              $t("Pagination text", [dataMeta.from, dataMeta.to, dataMeta.of])
            }}</span>
          </b-col>
          <!-- Pagination -->
          <b-col
            cols="12"
            sm="6"
            class="d-flex align-items-center justify-content-center justify-content-sm-end"
          >
            <b-pagination
              v-model="currentPage"
              :total-rows="totalQuotations"
              :per-page="perPage"
              first-number
              last-number
              class="mb-0 mt-1 mt-sm-0"
              prev-class="prev-item"
              next-class="next-item"
            >
              <template #prev-text>
                <feather-icon icon="ChevronLeftIcon" size="18" />
              </template>
              <template #next-text>
                <feather-icon icon="ChevronRightIcon" size="18" />
              </template>
            </b-pagination>
          </b-col>
        </b-row>
      </div>
    </b-card>
  </div>
</template>

<script>
import {
  BButton,
  BMedia,
  BAvatar,
  BRow,
  BCol,
  BFormGroup,
  BFormInput,
  BForm,
  BTable,
  BCard,
  BCardHeader,
  BCardTitle,
  BFormCheckbox,
  BPagination,
  BDropdown,
  BDropdownItem,
  BModal,
  VBModal,
  BCardText,
  BBadge,
  BInputGroup,
} from "bootstrap-vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import "flatpickr/dist/flatpickr.css";
import instance from "@/libs/axios";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import useQuotationList from "./useQuotationList";
import Ripple from "vue-ripple-directive";
export default {
  directives: {
    "b-modal": VBModal,
    Ripple,
  },
  components: {
    BButton,
    BDropdown,
    BDropdownItem,
    BMedia,
    BBadge,
    BAvatar,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BForm,
    BTable,
    BCard,
    BCardHeader,
    BCardTitle,
    BFormCheckbox,
    vSelect,
    flatPickr,
    BPagination,
    BModal,
    VBModal,
    BCardText,
    BInputGroup,
  },
  props: {
    clients: Array,
    emergencyDegrees: Array,
    subProfiles: Array,
    quotationUsers: Array,
  },
  data() {
    return {
      selectedClient: null,
      isAdmin: null,
      timeoutId: null,
      quotationDetails: [],
      quantities: [],
      prices: [],
    };
  },

  created() {
    const userData = JSON.parse(localStorage.getItem("userData"));
    if (userData.role === "admin") {
      this.isAdmin = true;
      this.tableColumns = [
        { key: "ref", label: this.$t("Ref") },
        { key: "client.name", label: this.$t("Client") },
        { key: "start_date", label: this.$t("Start date") },
        { key: "end_date", label: this.$t("End date") },
        { key: "alert", label: this.$t("Alert") },

        { key: "created_by.name", label: this.$t("Created by") },
        { key: "created_at", label: this.$t("Created at") },
        { key: "actions", label: this.$t("Actions") },
      ];
    }
  },

  methods: {
    refreshTable() {
      this.refetchData();
    },
    async validateQuotation(data) {
      const result = await this.$bvModal.msgBoxConfirm(
        this.$t("Are you sure you want to validate this quotation?"),
        {
          title: "Alert",
          size: "sm",
          okVariant: "primary",
          okTitle: this.$t("Yes"),
          cancelTitle: this.$t("No"),
          cancelVariant: "outline-secondary",
          hideHeaderClose: false,
          centered: true,
        }
      );

      if (!result) return;

      try {
        await instance.patch(`quotations/quotations-details/${data.item.id}`, {
          state: 3,
        });

        this.refetchData();

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Alert"),
            text: this.$t("The quotation has been verified"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    showFile(blob) {
      const binaryString = window.atob(blob);
      const binaryLen = binaryString.length;
      const bytes = new Uint8Array(binaryLen);
      for (let i = 0; i < binaryLen; i++) {
        const ascii = binaryString.charCodeAt(i);
        bytes[i] = ascii;
      }
      var newBlob = new Blob([bytes], { type: "application/pdf" });

      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(newBlob);
        return;
      }

      const data = window.URL.createObjectURL(newBlob);
      var link = document.createElement("a");
      link.href = data;
      link.download = "quotation.pdf";
      link.click();
      setTimeout(function () {
        window.URL.revokeObjectURL(data);
      }, 100);
    },
    async printQuotation(data) {
      try {
        const res = await instance.put(
          `quotations/quotations-details/${data.item.id}`
        );

        this.showFile(res.data);
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async updateQuotationDetail() {
      try {
        const product_combinations = this.quotationDetails.map((e) =>
          parseInt(e.product_combination.id)
        );
        const prices = this.prices;
        const quantities = this.quantities;

        await instance.post(
          `quotations/quotations-details/${this.quotationDetails[0].quotation.id}`,
          {
            start_date: this.startDate,
            end_date: this.endDate,
            product_combinations,
            quantities,
            prices,
          }
        );

        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Success"),
            text: this.$t("Quotation has been updated"),
            icon: "CheckIcon",
            variant: "success",
          },
        });
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    async openConsulteModal(data) {
      try {
        const res = await instance.get(
          `quotations/quotations-details/${data.item.id}`
        );

        this.quotationDetails = res.data;
        this.$refs[`consuler${data.item.id}modal`].show();
      } catch (err) {
        this.$toast({
          component: ToastificationContent,
          props: {
            title: this.$t("Error"),
            text: err.message,
            icon: "AlertTriangleIcon",
            variant: "danger",
          },
        });
      }
    },
    fetchClientOptions(search, loading) {
      clearTimeout(this.timeoutId);
      this.timeoutId = setTimeout(async () => {
        try {
          loading(true);
          const res = await instance.get("/parameters/clients/", {
            params: { search },
          });
          this.clientsRes = res.data.results;
        } catch (err) {
          const error = err.response
            ? Object.values(err.response.data)[0][0]
            : err.message;

          this.$toast({
            component: ToastificationContent,
            props: {
              title: this.$t("Error"),
              text: error ?? this.$t("An error has occurred. Please try again"),
              icon: "AlertTriangleIcon",
              variant: "danger",
            },
          });
        } finally {
          loading(false);
        }
      }, 500);
    },
  },

  setup(props) {
    const {
      fetchQuotations,
      tableColumns,
      perPage,
      currentPage,
      totalQuotations,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
      selectedDate,
      createdByValue,
    } = useQuotationList(5);

    return {
      // Sidebar
      fetchQuotations,
      tableColumns,
      perPage,
      currentPage,
      totalQuotations,
      dataMeta,
      perPageOptions,
      refValue,
      sortBy,
      isSortDirDesc,
      refOrderListTable,
      refetchData,
      subProfileValue,
      clientValue,
      shippingDateValue,
      emergencyDegreeValue,
      selectedDate,
      createdByValue,
    };
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
